<template>
    <div id="app">
        <router-view v-slot="{Component}" v-if="!showLogin">
            <keep-alive include="search">
                <component :is="Component" />
            </keep-alive>
        </router-view>
        <van-dialog v-model:show="showLogin" :showConfirmButton="true" :closeOnPopstate="false" :beforeClose="login" confirm-button-text="立即登录" :close-on-popstate="false" title="身份验证">
            <van-cell-group :border=false class='form'>
                <van-field v-model="username" autofocus left-icon="manager" placeholder="用户名" />
                <van-field v-model="password" left-icon="lock" type='password' placeholder="密码" />
            </van-cell-group>
        </van-dialog>
    </div>
</template>
<script>
import { User } from "@/api/user.js";
import { Wiki } from "@/api/wiki.js";
export default {
    components: {
        [Wiki.name]: Wiki,
        [User.name]: User,
    },
    provide() {
        return {}
    },
    data() {
        return {
            showLogin: 0,
            username: 'admin',
            password: '',
            conf:this.$store.get('conf')||this.getConf()
        };
    },
    created: function() {
        if(!this.$store.get('eToken')){
            this.showLogin=1;
        }
    },
    computed: {

    },
    methods: {
        /*checkToken() {
            User.info()
                .then(res => {
                    if (res && res.code == 1) {
                        this.$store.set('userinfo', res.data);
                    }else{
                        this.$store.remove('eToken', null);
                        this.showLogin=1;
                    }
                });
        },*/
        //登录
        login(action) {
            if (action === "confirm") {
                if (this.username == '') {
                    this.$toast("用户名不能为空");
                    return false;
                }
                if (this.password == '') {
                    this.$toast("密码不能为空");
                    return false;
                } else {
                    User.login({ 'username': this.username, 'password': this.password })
                        .then(res => {
                            if (res && res.code == 1) {
                                this.$store.set('eToken', res.data.userinfo.eToken);
                                this.$store.set('userinfo', res.data.userinfo);
                                this.$store.set('conf', res.data.conf);
                                this.showLogin = false;
                                location.href = '/';
                            } else {
                                return false;
                            }
                        })
                }
            }
        },
        getConf() {
            var that=this;
            if(this.$store.get('eToken')){
                Wiki.conf({'showloading': false })
                .then(res => {
                    this.conf=res.data;
                    this.$store.set('conf', res.data);
                })
            }
        }
    },
    watch: {},
};
</script>
<style>
@import "./assets/css/base.css";

body {
    font-size: 16px;
    background-color: #f8f8f8;
    -webkit-font-smoothing: antialiased;
}
</style>