const base = {
    //url: '/api'
    url: 'https://lan.ybbbh.cn/api',
    server_url: 'https://lan.ybbbh.cn',
    goBack: function(that) {
        if (that.routerName) {
            that.$router.replace({
                name: that.routerName
            });
        } else {
            that.$router.go(-1);
        }
    },

}
export default base