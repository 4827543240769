import request from '@/untils/request'
import QS from 'qs'
import base_ from '@/untils/base';
/**
 * post方法，对应post请求
 * @desc注册请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export const Wiki = {

    search: function(data) {
        return request({
            url: `${base_.url}/wiki/search`,
            method: 'post',
            data: QS.stringify(data),
        })
    },

    detail: function(data) {
        return request({
            url: `${base_.url}/wiki/detail`,
            method: 'post',
            data: QS.stringify(data),
            showloading: data.showloading,
        })
    },
    edit: function(data) {
        return request({
            url: `${base_.url}/wiki/editsave`,
            method: 'post',
            data: QS.stringify(data),
            showloading: true,
        })
    },
    addsave: function(data) {
        return request({
            url: `${base_.url}/wiki/addsave`,
            method: 'post',
            data: QS.stringify(data),
            showloading: true,
        })
    },
    price_edit: function(data) {
        return request({
            url: `${base_.url}/wiki/price_edit`,
            method: 'post',
            data: QS.stringify(data),
            showloading: true,
        })
    },
    price_add: function(data) {
        return request({
            url: `${base_.url}/wiki/price_add`,
            method: 'post',
            data: QS.stringify(data),
            showloading: true,
        })
    },
    price_del: function(data) {
        return request({
            url: `${base_.url}/wiki/price_del`,
            method: 'post',
            data: QS.stringify(data),
            showloading: true,
        })
    },
    del: function(data) {
        return request({
            url: `${base_.url}/wiki/del`,
            method: 'post',
            data: QS.stringify(data),
            showloading: true,
        })
    },
    recycle: function(data) {
        return request({
            url: `${base_.url}/wiki/recycle`,
            method: 'post',
            data: QS.stringify(data),
        })
    },
    recovery: function(data) {
        return request({
            url: `${base_.url}/wiki/recovery`,
            method: 'post',
            data: QS.stringify(data),
        })
    },
    clear: function(data) {
        return request({
            url: `${base_.url}/wiki/clear`,
            method: 'post',
            data: QS.stringify(data),
        })
    },
    conf: function(data) {
        return request({
            url: `${base_.url}/wiki/conf`,
            method: 'post',
            data: QS.stringify(data),
        })
    },
}