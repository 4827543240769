import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import VueClipboard from 'vue-clipboard2';
import '@vant/touch-emulator';
import store from 'store';
import base from '@/untils/base';

import {
    Form,
    Loading,
    Row,
    Col,
    Button,
    NavBar,
    Cell,
    Icon,
    Divider,
    Field,
    Tag,
    CellGroup,
    Toast,
    SwipeCell,
    List,
    Dialog,
    Lazyload,
    Popup,
    Empty,
    Badge,
    PullRefresh,
    Uploader,
    Collapse,
    CollapseItem,
    Popover,
    Skeleton,
    ImagePreview,
    DropdownMenu,
    DropdownItem,
    Image as VanImage
} from 'vant';


const app = createApp(App);
//全局注册
app.config.globalProperties.$store = store;
app.config.globalProperties.$base = base;

app.use(router).use(VueClipboard)
    .use(Form)
    .use(Loading)
    .use(Row)
    .use(Col)
    .use(Button)
    .use(NavBar)
    .use(Cell)
    .use(Icon)
    .use(Divider)
    .use(Field)
    .use(Tag)
    .use(CellGroup)
    .use(Toast)
    .use(SwipeCell)
    .use(List)
    .use(Dialog)
    .use(Lazyload, {
        lazyComponent: true,
    })
    .use(Popup)
    .use(Empty)
    .use(Badge)
    .use(PullRefresh)
    .use(Uploader)
    .use(Collapse)
    .use(CollapseItem)
    .use(Popover)
    .use(Skeleton)
    .use(ImagePreview)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(VanImage);



import header from "./components/header.vue";
app.component('my-header', header);


app.mount('#app');